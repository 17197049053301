module services {
    export module logistics {
        export class commercialInvoiceDeliveryInstructionAllocationService implements interfaces.logistics.ICommercialInvoiceDeliveryInstructionAllocationService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDIForCI(ownerEntityId: number, supplierId: number, consignmentTypeId: number, consignmentId?: number): ng.resource.IResourceClass<interfaces.logistics.ICIDIDisplay> {
                return this.$resource<interfaces.logistics.ICIDIDisplay>(this.ENV.DSP_URL + "Consignment/GetDIForCI", {
                    ownerEntityId: ownerEntityId,
                    supplierId: supplierId,
                    consignmentTypeId: consignmentTypeId,
                    consignmentId: consignmentId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1
                            }
                        }
                    });
            }
            
            GetDIAllocationLines(): ng.resource.IResourceClass<interfaces.logistics.ICIDILineDisplay> {

                return this.$resource<interfaces.logistics.ICIDILineDisplay>(this.ENV.DSP_URL + "Consignment/GetDIAllocationLines", {
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            CreateFromDI(ownerEntityId: number, typeId: number, shipperId: number, consigneeId: number, supplierId: number, customerId: number, customNumber?: string, counterId?: number, routeCodeId?: number,authorizeVarianceUserId?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/CreateFromDI", {
                    ownerEntityId: ownerEntityId,
                    typeId: typeId,
                    shipperId: shipperId,
                    consigneeId: consigneeId,
                    supplierId: supplierId,
                    customerId: customerId,
                    routeCodeId: routeCodeId,
                    customNumber: customNumber,
                    counterId: counterId,
                    authorizeVarianceUserId:authorizeVarianceUserId

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            UpdateFromDI(consignmentId: number,authorizeVarianceUserId?: number/*, diWithAllocationLineSubmit: interfaces.logistics.ICIDIWithAllocationLineSubmit*/): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Consignment/UpdateFromDI", {
                    consignmentId: consignmentId,
                    authorizeVarianceUserId:authorizeVarianceUserId
                    /*,diWithAllocationLineSubmit: diWithAllocationLineSubmit */ //removed this line of Code that is causing the DI Lines to be submitted as part of the QueryString!! [VH 20181206 #38029]

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }
        }
    }
    angular.module("app").service("commercialInvoiceDeliveryInstructionAllocationService", services.logistics.commercialInvoiceDeliveryInstructionAllocationService);
}